import { useApi } from '~/composables/api/useApi'

export function useSearch() {
  const { fetchWithAuth } = useApi()
  const RESSOURCE = '/search'

  // GET Requests

  async function fetchSearchFulltext(providedOptions: {
    term: string
    start?: any
    rows?: any
    filter?: any
    facetLimit?: any
  }) {
    const defaultOptions = {
      facetLimit: 1000,
    }
    const options = { ...defaultOptions, ...providedOptions }

    return await fetchWithAuth(`${RESSOURCE}/fulltext`, 'GET', {
      providedOptions: {
        query: options.term,
        type: 'LinesLc',
        start: options.start,
        rows: options.rows,
        filter: options.filter,
        legacy: false,
        'facet.limit': options.facetLimit,
      },
    })
  }

  async function fetchTags(providedOptions: {
    collId: number
    docIds: any
    pageIds: any
  }) {
    return await fetchWithAuth(`${RESSOURCE}/tags`, 'GET', {
      providedOptions,
    })
  }

  // POST Requests

  async function fetchSearchResults(providedOptions: {
    term: string
    documents?: Array<number>
    titles?: any
    page?: any
    fuzziness?: any
    offset?: number
    limit?: number
  }) {
    const defaultOptions = {
      limit: 10,
      offset: 0,
    }
    return await fetchWithAuth(
      'https://transkribus.eu/r/search/search',
      'POST',
      {
        data: JSON.stringify({ ...providedOptions, ...defaultOptions }),
        providedHeaders: {
          'Content-Type': 'application/json; charset=utf-8',
          Accept: 'application/json; charset=utf-8',
        },
      }
    )
  }

  return { fetchSearchFulltext, fetchTags, fetchSearchResults }
}
